import { CommandBarClientAPI } from "commandbar/build/commandbar";
import { pipe, subscribe } from "wonka";
import { Analytics } from "../analytics";
import { reclaim } from "../reclaim-api";
import { Task } from "../reclaim-api/Tasks";
import { byKey } from "./arrays";

/**
 * Help
 */

export const addHelpCallbacks = (cb?: CommandBarClientAPI) => {
  cb?.addCallback("requestHelp", (args, context) => {
    if (!Analytics.intercom) return console.warn("No intercom to send issue", args.issue);

    Analytics.intercom?.("showNewMessage", args.issue);
  });

  cb?.addCallback("requestFeature", (args, context) => {
    if (!Analytics.intercom) return console.warn("No intercom to send feature", args.feature);
    Analytics.intercom?.("showNewMessage", args.feature);
  });
};

/**
 * Tasks
 */

export const addTaskCallbacks = (cb?: CommandBarClientAPI) => {
  cb?.addCallback("startTask", (args, context) => {
    reclaim.planner.startTask(args.selected).catch((e) => {
      console.error(`Failed to start task from command bar ${e}`);
    });
  });

  cb?.addCallback("stopTask", (args, context) => {
    reclaim.planner.stopTask(args.selected).catch((e) => {
      console.error(`Failed to stop task from command bar ${e}`);
    });
  });

  cb?.addCallback("snoozeTask", (args, context) => {
    reclaim.tasks
      .update({
        ...args.selected,
        snoozeUntil: args.time,
      } as Task)
      .catch((e) => {
        console.error(`Failed to update snooze on task from command bar ${e}`);
      });
  });
};

export const initTaskData = (cb?: CommandBarClientAPI) => {
  pipe(
    reclaim.tasks.listAndWatch$$({ instances: true }),
    subscribe(async (tasks?: Task[]) => {
      const filteredAndMapped = tasks?.map((t) => {
        const instances = (t.instances || [])
          .filter((instance) => (instance.end?.getTime() || 0) > new Date().getTime())
          .sort(byKey("start"));

        return {
          ...t,
          nextEvent: instances[0],
        };
      });

      cb?.addContext(
        {
          tasks: filteredAndMapped,
        },
        undefined,
        undefined
      );
    })
  );
};

/**
 * Habits
 */

export const initHabitData = (cb?: CommandBarClientAPI) => {
  pipe(
    reclaim.habits.listAndWatch$$(),
    subscribe((habits) => {
      cb?.addContext(
        {
          habits: habits,
        },
        undefined,
        undefined
      );
    })
  );
};
